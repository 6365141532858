<template>
    <div v-if="waited"> {{html}}</div>
    <posts v-else :item0="gazette0" :items="gazettes" />
</template>

<script>
import axios from 'axios';
import Posts from '@/components/Posts.vue'

export default {
  name: 'Gazettes',
  components: {Posts},

 data() {
      return {
          html: "Chargement...",
          gazettes : [],
          gazette0 : {},
          waited : true
      }
  },

  created : function() {
      var baseurl = "/api/"
      //  var baseurl = "/data"
      axios.get(baseurl+'?type=gazette&num=all').then(response =>{
        
        console.log(response.data);

        this.gazettes = response.data;
        this.gazettes.reverse();
        this.gazette0 = this.gazettes.pop();
        this.gazettes.reverse();

        this.waited=false;

     }).catch(error => {
         this.html=error;
     })
  }
}

</script>

